"use strict";

var app = {
  loader: '<div id="loading" class="loading"><div><img src="/assets/themes/reflex/img/loader.gif" /></div></div>',
  page: 1,
  emailPreferencesToggle: function emailPreferencesToggle(selected, id, value) {
    $("#" + id).val(value);
    var wrap = $("#" + id + "-chk .toggle-preferences-btn");
    var selected = $(selected);
    wrap.removeClass("checked");
    selected.addClass("checked");
  },
  setStar: function setStar(value, e) {
    $('input[name="' + e + '"]').val(value);

    var selected = $("#selected-stars");
    selected.find(".rating-stars-select").removeClass("filled");
    for (var x = 1; x <= value; x++) {
      selected.find('.rating-stars-select[data-value="' + x + '"]').addClass("filled");
    }
  },
  validateEmail: function validateEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  },
  validateForm: function validateForm() {
    $('#validate-btn').click(function (e) {

      var isValid = true;
      var formGroups = $('.form-group.required-field input');
      formGroups.each(function (key, row) {
        var row = $(row);
        var isEmail = row.attr('name').indexOf('email');
        var value = row.val();
        if (isEmail !== -1) {
          if (app.validateEmail(value) == false) {
            row.addClass('required-border');
            isValid = false;
          } else {
            if (row.hasClass('required-border')) {
              row.removeClass('required-border');
            }
          }
        } else {
          if (value == '') {
            row.addClass('required-border');
            isValid = false;
          } else {
            if (row.hasClass('required-border')) {
              row.removeClass('required-border');
            }
          }
        }
      });

      if (isValid == false) {
        e.preventDefault();
      }
    });
  },
  populateCheckbox: function populateCheckbox(e) {
    var selected = $(e);
    var checkbox = selected.find("input");
    var parent = selected.attr("data-parent");
    var dropdown = selected.hasClass("check_wrap_dropdown_item");
    var value = selected.attr("data-value");

    if (checkbox.is(":checked") == true) {
      checkbox.prop("checked", false);
      selected.removeClass("selected");
      /// AJAX REQUEST
      if (selected.hasClass("filter-selection-by-attribute") === true) {
        app.filterProductByAttribute(parent);
      }
    } else {
      var selected_parent = $('.check_wrap_item[data-parent="' + parent + '"]');
      selected_parent.removeClass("selected");
      var input_parent = selected_parent.find("input");
      $.each(input_parent, function (key, row) {
        $(row).prop("checked", false);
      });

      checkbox.prop("checked", true);

      selected.addClass("selected");
      /// AJAX REQUEST
      if (selected.hasClass("filter-selection-by-attribute") === true) {
        app.filterProductByAttribute(parent);
      }

      // is a dropdown?
      if (dropdown == true) {
        app.showDropdownMenu(parent);
        $('.select_item[data-parent="' + parent + '"]').html(value).addClass("selected");
      }
    }
  },
  goBack: function goBack() {
    window.history.back();
  },
  selectedCategory: function selectedCategory(slug) {
    window.location.href = "/get-a-quote?category=" + slug;
  },

  selectedParent: function selectedParent(parent) {
    $.fancybox.close();
    // Category Select Parent Window

    var data = {
      title: "Choose Type",
      subTitle: "",
      content: app.loader
    };

    $.fancybox.open({
      type: "html",
      src: '<div class="fc-content rounded frm-dialog">' + '<div class="top">' + '<span id="back_btn" class="back-btn" onclick="app.triggerCategorySelectDialog();"><i class="fas fa-chevron-left"></i></span>' + "<h2>" + data.title + "</h2>" + "</div>" + '<div id="dialog-content" class="dialog-item-inner">' + data.content + "</div>" + "</div>",
      opts: {
        clickSlide: false,
        clickOutside: false,
        afterShow: function afterShow(instance, current) {
          $.ajax({
            url: "./api/categories/" + parent
          }).done(function (response) {
            var html = "<ul>";
            if (response.length == 0) {
              html += "<li>No Categories Found</li>";
            } else {
              $.each(response, function (key, row) {
                html += "<li onclick=\"app.selectedCategory('" + row.slug + "');\">" + row.name + "</li>";
              });
            }
            html += "</ul>";

            $("#dialog-content").html(html);
          });
        }
      }
    });
  },
  setPayments: function setPayments(clientToken) {
    //braintree.setup(clientToken, "dropin", {
    //  container: "payment-form"
    //});
  },
  paymentForm: function paymentForm(data) {
    $.ajax({
      url: "/forms/payment",
      success: function success(response, textStatus, xhr) {
        $("#dialog-content").html(response);
        var form = $("#submit_payment_frm");
        form.find('input[name="id"]').val(data.id);
        form.find('input[name="quote_product_id"]').val(data.productId);
        var stripe_env_data = $('input[name="token"]').val();
        // Stripe API Key
        var loader = $("#payment-form-loader");
        var stripe = Stripe(stripe_env_data);
        var elements = stripe.elements();
        // Custom Styling
        var style = {
          base: {
            color: "#32325d",
            lineHeight: "24px",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        };
        // Create an instance of the card Element
        var card = elements.create("card", { style: style });
        // Add an instance of the card Element into the `card-element` <div>
        card.mount("#card-element");
        // Handle real-time validation errors from the card Element.
        card.addEventListener("change", function (event) {
          var displayError = document.getElementById("card-errors");
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = "";
          }
        });
        // Handle form submission
        var form = document.getElementById("submit_payment_frm");
        form.addEventListener("submit", function (event) {
          event.preventDefault();
          $("#submit_payment_frm").css("opacity", 0.5);
          loader.removeClass("hidden");
          stripe.createToken(card).then(function (result) {
            if (result.error) {
              // Inform the user if there was an error
              var errorElement = document.getElementById("card-errors");
              errorElement.textContent = result.error.message;
              $("#submit_payment_frm").css("opacity", 1);
              loader.addClass("hidden");
            } else {
              stripeTokenHandler(result.token);
            }
          });
        });
        // Send Stripe Token to Server
        function stripeTokenHandler(token) {
          // Insert the token ID into the form so it gets submitted to the server
          var form = document.getElementById("submit_payment_frm");
          // Add Stripe Token to hidden input
          var hiddenInput = document.createElement("input");
          hiddenInput.setAttribute("type", "hidden");
          hiddenInput.setAttribute("name", "stripeToken");
          hiddenInput.setAttribute("value", token.id);
          form.appendChild(hiddenInput);
          // Submit form
          form.submit();
        }
      },
      error: function error(XMLHttpRequest, textStatus, errorThrown) {
        var response = '<div style="padding:20px;text-align:center;">Our Payment system isn\'t available right now. Please try again later.</div>';
        $("#dialog-content").html(response);
      }
    });
  },
  submitOfferForm: function submitOfferForm(data) {
    $.ajax({
      url: "/forms/submit_offer?id=" + data.id
    }).done(function (response) {
      $("#dialog-content").html(response);
      // CURRENCY TOGGLE
      $("#currency-input-wrap_sel .symbol").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var symbol = $(".currency-input-wrap .symbol");
        var currencyId = selected.data("currency-id");
        symbol.each(function (key, row) {
          var row = $(row);
          row.removeClass("active");
          if (row.data("currency-id") != currencyId) {
            row.addClass("active");
            $("#currency").val(row.data("currency-id"));
          }
        });
      });

      app.inputToggle();
      var form = $("#submit_offer_frm");
      var btn = $("#continue_btn");
      var id = data.id; // QUOTE ID FOR DIRECT
      form.find('input[name="quote_product_id"]').val(id);
      btn.click(function (e) {
        e.preventDefault();
        var price = $('input[name="price"]').val();
        var validation = $("#validation");
        validation.addClass("hidden");
        validation.html("");
        $("#submit-offer-step1 .currency-input-wrap").removeClass("required-outline");
        if (price != "") {
          $("#continue_btn").prop("disabled", true);
          $("#loader").removeClass("hidden");
          $("#submit_offer_frm").submit();
        } else {
          validation.html("Please enter your price");
          validation.removeClass("hidden");
          $("#submit-offer-step1 .currency-input-wrap").addClass("required-outline");
        }

        /*
        var data = form.find("input,textarea");
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: data
        }).done(function(response) {
          if (response.id) {
            var data = {
              title: "Submit Offer (&pound;3)",
              subTitle: "Enter your payment details:",
              content: app.loader,
              template: "payment",
              id: response.id,
              productId: id
            };
            app.fancyboxDialog(data);
          }
        });
        */
      });
    });
  },
  inputToggle: function inputToggle() {
    var toggle = $(".input-toggle");
    if (toggle.length > 0) {
      $.each(toggle, function (key, row) {
        var row = $(row);
        var input = row.find("input");
        var lbl = row.find(".input-toggle-lbl");
        lbl.click(function () {
          var selected = $(this);
          input.val(selected.data("value"));
          lbl.removeClass("active");
          selected.addClass("active");
          //
          if (input.attr("id") == "currency") {
            var symbol = $(".currency-input-wrap .symbol");
            var currencyId = selected.data("value");
            symbol.each(function (key, row) {
              var row = $(row);
              row.removeClass("active");
              if (row.data("currency-id") == currencyId) {
                row.addClass("active");
              }
            });
          }
        });
      });
    }
  },
  filterProductByAttribute: function filterProductByAttribute(parent) {
    //var data = ['attribute', 'cat'];
    //var selected = $('.filter-selection-by-attribute.selected input');
    //selected.each(function(key,row) {
    //var row = $(row);
    //if(typeof data['attribute'][parseInt(row.attr('data-attribute'))] === 'undefined') {
    //	data['attribute'][parseInt(row.attr('data-attribute'))] = [];
    //}
    //data['attribute'][parseInt(row.attr('data-attribute'))].push(parseInt(row.attr('value')));
    //});
    //data['cat'] = $('input[cat]').val();

    var data = $('.quote-frm-wrap input[type="hidden"], .filter-selection-by-attribute input[type="checkbox"]:checked');
    var required = $(".select_options_wrap label .required").length;
    var select_options_wrap = $("ul.select_options_wrap li");
    var selected = 0;
    $.each(select_options_wrap, function (key, row) {
      if ($(row).find(".selected").length > 0) {
        selected++;
      }
    });

    $.ajax({
      url: "./api/products/attributes?parent=" + parent,
      type: "POST",
      data: data
    }).done(function (response) {
      if (selected > 0) {
        $.each(response, function (key, row) {
          var chk_items = $('.check_wrap_item[data-parent="' + key + '"]');

          chk_items.addClass("disabled");

          if (chk_items.parent(".check_wrap_dropdown").length != 0) {
            $(chk_items[0]).removeClass("disabled");
          }

          $.each(row, function (attrId, attrValue) {
            $.each(chk_items, function (chk_key, chk_item) {
              var chk_item = $(chk_item);

              if (chk_item.attr("data-value") == attrValue) {
                chk_item.removeClass("disabled");
              }
            });
          });

          // Remove Invalid selection from dropdown
          if ($('.check_wrap_dropdown_items[data-parent="' + key + '"]').length > 0) {
            // value
            var current_value = $('.select_item[data-parent="' + key + '"]').html();
            var tmp = $('.check_wrap_dropdown_item[data-parent="' + key + '"][data-value="' + current_value + '"]');

            var is_disabled = tmp.hasClass("disabled");
            if (is_disabled == true) {
              $(".select_item[data-parent='" + key + "']").html("Select").removeClass("selected");
              tmp.removeClass("selected");
              tmp.find("input").prop("checked", false);
            }
          }
          //
        });
      } else {
        var chk_items = $(".check_wrap_item");
        chk_items.removeClass("disabled");
      }

      var btn = $("#show_extra_btn");
      if (selected == 0) {
        btn.addClass("hidden");
      } else if (selected >= required) {
        btn.removeClass("hidden");
      } else {
        btn.addClass("hidden");
      }
    });
  },
  deleteOfferForm: function deleteOfferForm(data) {
    $.ajax({
      url: "/forms/delete_offer"
    }).done(function (response) {
      $("#dialog-content").html(response);
      var form = $("#delete_offer_frm");
      var btn = $("#delete_btn");
      form.find('input[name="quote_product_id"]').val(data.id);
      btn.click(function (e) {
        e.preventDefault();
        var input = form.find("input");
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: input
        }).done(function (response) {
          if (response.quote_product_id) {
            $.fancybox.close();
            var selected = $('ul li[data-quote-product-id="' + data.id + '"]');
            selected.fadeOut().remove();
          }
        });
      });
    });
  },
  archiveOfferForm: function archiveOfferForm(data) {
    $.ajax({
      url: "/forms/archive_offer"
    }).done(function (response) {
      $("#dialog-content").html(response);
      var form = $("#delete_quote_product_frm");
      var btn = $("#delete_btn");
      form.find('input[name="id"]').val(data.id);
      btn.click(function (e) {
        e.preventDefault();
        var input = form.find("input");
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: input
        }).done(function (response) {
          $.fancybox.close();
          if (response) {
            var selected = $('ul li[data-quote-product-id="' + data.id + '"]');
            selected.fadeOut().remove();
          }
        });
      });
    });
  },
  fancyboxDialog: function fancyboxDialog(data) {
    $.fancybox.close();

    var fancyboxBody = "";
    fancyboxBody += '<div class="fc-content rounded frm-dialog">';
    fancyboxBody += '<div class="top';
    if (data.subTitle != "") {
      fancyboxBody += " sub-title-header";
    }
    fancyboxBody += '">';
    if (data.title != "") {
      fancyboxBody += "<h2>" + data.title + "</h2>";
    }
    if (data.subTitle != "") {
      fancyboxBody += "<h3>" + data.subTitle + "</h2>";
    }
    fancyboxBody += "</div>";
    fancyboxBody += '<div id="dialog-content" class="dialog-item-inner">' + data.content + "</div>";
    fancyboxBody += "</div>";

    // Open customized confirmation dialog window
    $.fancybox.open({
      type: "html",
      src: fancyboxBody,
      opts: {
        clickSlide: false,
        clickOutside: false,
        afterShow: function afterShow(instance, current) {
          if (data.template == "categories") {
            $.ajax({
              url: "./api/categories"
            }).done(function (response) {
              if (response.length == 1) {
                app.selectedParent(response[0].id);
              } else {
                var html = "<ul>";
                $.each(response, function (key, row) {
                  html += '<li onclick="app.selectedParent(' + row.id + ');">' + row.name + "</li>";
                });
                html += "</ul>";
                $("#dialog-content").html(html);
              }
            });
          } else if (data.template == "submit_offer") {
            app.submitOfferForm(data);
          } else if (data.template == "delete_offer") {
            app.deleteOfferForm(data);
          } else if (data.template == "payment") {
            app.paymentForm(data);
          } else if (data.template == "contact") {
            app.contactDialog(data);
          } else if (data.template == "dealer_contact") {
            app.dealerContactDialog(data);
          } else if (data.template == "notes") {
            app.notesDialog(data);
          } else if (data.template == "view_offer") {
            app.viewOfferDialog(data);
          } else if (data.template == "achiveOffer") {
            app.archiveOfferForm(data);
          } else if (data.template == "delete_image") {
            app.deleteImageDialog(data);
          } else if (data.template == "confirm_sale") {
            app.confirmSaleDialog(data);
          } else if (data.template == "terms") {
            app.termsDialog(data);
          } else if (data.template == "privacy") {
            app.privacyDialog(data);
          } else if (data.template == "get-a-quote-product") {
            app.getProductQuote(data);
          } else if (data.template == "new-user-registration") {
            app.newUserRegistration(data);
          } else if (data.template == "added-to-cart") {
            app.addedToCart(data);
          }
        }
      }
    });
  },
  getProductQuote: function getProductQuote(data) {
    $.ajax({
      url: "/forms/product?id=" + data.productId
    }).done(function (response) {
      $("#dialog-content").html(response);

      $("#select_extras li:not(.selected)").click(function () {
        var selected = $(this);
        var chk = selected.find("input");
        chk = $(chk[0]);
        if (chk.is(":checked") === true) {
          selected.removeClass("selected");
          chk.prop("checked", false);
        } else {
          selected.addClass("selected");
          chk.prop("checked", true);
        }
      });
    });
  },
  notesDialog: function notesDialog(data) {
    $.ajax({
      url: "/forms/notes"
    }).done(function (response) {
      $("#dialog-content").html(response);
      var dialogNotes = $("#attach_notes_txt");
      var notes = $("#notes_txt");
      dialogNotes.val(notes.val());
      $("#attach_notes_btn").click(function (e) {
        e.preventDefault();
        notes.val(dialogNotes.val());
        $.fancybox.close();
      });
    });
  },
  newUserRegistration: function newUserRegistration(data) {
    $.ajax({
      url: "/ajax/new-user-registeration"
    }).done(function (response) {
      $("#dialog-content").html(response);
    });
  },
  addedToCart: function addedToCart(data) {
    $.ajax({
      url: "/forms/added-to-cart"
    }).done(function (response) {
      $("#dialog-content").html(response);
    });
  },
  termsDialog: function termsDialog(data) {
    $.ajax({
      url: "/api/terms"
    }).done(function (response) {
      var html = '<div class="general-dialog-txt">';
      html += response.content;
      html += "</div>";
      $("#dialog-content").html(html);
    });
  },
  privacyDialog: function privacyDialog(data) {
    $.ajax({
      url: "/api/privacy"
    }).done(function (response) {
      var html = '<div class="general-dialog-txt">';
      html += response.content;
      html += "</div>";
      $("#dialog-content").html(html);
    });
  },
  contactDialog: function contactDialog(data) {
    $.ajax({
      url: "/members/contact"
    }).done(function (response) {
      var target = $("#dialog-content");
      target.html(response);
      target.find("#phone-id-txt").html(data.telephone);
      target.find("#name-id-txt").html(data.dealer);
      target.find("#order-id-txt").html(data.orderId);
    });
  },
  dealerContactDialog: function dealerContactDialog(data) {
    $.ajax({
      url: "/get-a-quote/results/contact?id=" + data.notification
    }).done(function (response) {
      var target = $("#dialog-content");
      target.html(response);
      target.find("#phone-id-txt").html('<a href="tel:' + data.telephone + '"> click to call:' + data.telephone + '</a>');
      target.find("#email-id-txt").html('<a href="mailto:' + data.email + '">' + data.email + '</a>');
      target.find("#address-id-txt").html(data.address.replace(",,", ","));
      target.find('.business-title-txt').html(data.dealer);
    });
  },
  viewOfferDialog: function viewOfferDialog(data) {
    $.ajax({
      url: "/members/view-offer?id=" + data.orderId
    }).done(function (response) {
      $("#dialog-content").html(response).promise().done(function () {
        $("#dialog-content .call-dealer-btn").click(function (e) {
          e.preventDefault();
          var selected = $(this);
          var data = {
            title: "Contact " + selected.attr("data-dealer-title"),
            subTitle: "",
            content: app.loader,
            template: "contact",
            orderId: selected.attr("data-orderId"),
            dealer: selected.attr("data-dealer-title"),
            telephone: selected.attr("data-dealer-telephone")
          };
          app.fancyboxDialog(data);
        });
      });
    });
  },
  confirmSaleDialog: function confirmSaleDialog(data) {
    $.ajax({
      url: "/forms/confirm"
    }).done(function (response) {
      $("#dialog-content").html(response);
      $('input[name="id"]').val(data.orderId);
      var form = $("#dialog-content form");
      if (data.dealer == "true") {
        form.append('<input type="hidden" name="dealer" value="1" />');
      }
      if (data.customer == "true") {
        form.append('<input type="hidden" name="customer" value="1" />');
      }
      $("#confirm_frm_btn").click(function (e) {
        e.preventDefault();
        var input = form.find("input");
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: input
        }).done(function (response) {
          //$.fancybox.close();
          $("#confirm_btn").hide();
          if (response === 1) {
            $("#confirm-sale-frm").hide();
            $("#confirm-dealer-msg").fadeIn();
          }
        });
      });
    });
  },
  deleteImageDialog: function deleteImageDialog(data) {
    $.ajax({
      url: "/forms/delete_image"
    }).done(function (response) {
      $("#dialog-content").html(response);
      $('input[name="imageId"]').val(data.imageId);
      var form = $("#dialog-content form");
      $("#confirm_delete_image_btn").click(function (e) {
        console.log("remove clicked");
        e.preventDefault();
        var input = form.find("input");
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: input
        }).done(function (response) {
          //$.fancybox.close();
          console.log(response);
          $("#confirm_delete_image_btn").hide();
          if (response === 1) {
            $("#confirm-del-msg").hide();
            $("#confirm-image-delete").fadeIn();
            $("#marketimage-" + data.imageId).hide();
          } else {
            console.log(response);
          }
        });
      });
    });
  },
  dashboard: function dashboard() {
    if ($(".submit-offer-btn").length > 0) {
      $(".submit-offer-btn").unbind().on("click", function (e) {
        var data = {
          id: $(this).data("quote-product-id"),
          title: "Submit Offer",
          subTitle: "Please complete the following to submit your offer:",
          content: app.loader,
          template: "submit_offer"
        };
        app.fancyboxDialog(data);
      });
    }
    if ($(".delete-offer-btn").length > 0) {
      $(".delete-offer-btn").unbind().on("click", function (e) {
        e.preventDefault();
        var data = {
          id: $(this).data("quote-product-id"),
          title: "Delete Quote",
          subTitle: "Are you sure you do not want to accept this Enquiry",
          content: app.loader,
          template: "delete_offer"
        };
        app.fancyboxDialog(data);
      });
    }
  },
  showDropdownMenu: function showDropdownMenu(parent) {
    var select = $('.select_item[data-parent="' + parent + '"]');
    var menu = $('.check_wrap_dropdown_items[data-parent="' + parent + '"]');
    if (menu.hasClass("show")) {
      menu.removeClass("show");
      select.removeClass("selected");
    } else {
      menu.addClass("show");
      select.addClass("selected");
    }
  },
  triggerCheckbox: function triggerCheckbox(elem) {
    var selected = $(elem);
    var input = selected.find("div input");
    var checked = input.is(":checked");
    input.attr("checked", !checked).change();
    $("#filter-dropdowns").submit();
  },
  callDealer: function callDealer() {},

  notifications: function notifications() {
    $(".notification-link").bigSlide({
      menu: "#notifications",
      side: "right",
      menuWidth: "360px"
    });
    $(".notification-link").click(function () {
      var isOpen = $(".notification-link").hasClass("active");
      if (isOpen == true) {
        $("#page-wrap-overlay").removeClass("hidden");
        $("#close-notifications-tab").addClass("open");
      } else {
        $("#page-wrap-overlay").addClass("hidden");
        $("#close-notifications-tab").removeClass("open");
      }
    });

    $("#close-notifications-tab").click(function () {
      $(".notification-link").trigger("click");
    });
    app.loadNotifyItems(1, 6);

    $("#load-notification-btn").click(function () {
      app.page++;
      app.loadNotifyItems(app.page, 6);
    });

    //
  },
  loadNotifyItems: function loadNotifyItems(page, limit) {
    var loader = $("#notification-loader");
    var target = $("#notification-items");
    var counter = $("#notification-counter");

    $.ajax({
      url: "/api/notifications?page=" + page + "&limit=" + limit
    }).done(function (response) {
      var results = "";
      var profile = response.profile;
      if (profile !== 'BASIC') {
        $.each(response.items.data, function (key, row) {
          // EXISTS
          var exists = $("#notify_" + row["id"]);
          if (exists.length == 0) {
            results += '<li data-unread="' + row["unread"] + '" id="notify_' + row["id"] + '">';
            results += '<a href="' + row["link"] + '">';
            results += '<span class="mid-body">';
            results += "<span>";
            results += '<img src="/assets/themes/reflex/img/' + row["icon"] + '" />';
            results += "</span>";
            results += "<span>" + row["title"] + "</span>";
            results += "</span>";
            results += '<span class="mid-btm">';
            results += '<span class="mid-btm-col">';
            if (row["read_at"] == null) {
              results += '<span class="new-status">New</span>';
            }
            results += row["subTitle"];
            results += "</span>";
            results += '<span class="mid-btm-col">' + row["date"] + "</span>";
            results += "</span>";
            results += "</a>";
            results += "</li>";
          }
        });

        loader.addClass("hidden");

        target.append(results).removeClass("hidden");

        var total = $("#notification-items li").length;
        var unread = $('#notification-items li[data-unread="true"]').length;

        if (total == response.items.total) {
          $("#load-notification-btn").addClass("hidden");
        }

        counter.html(unread);
        if (unread == 0) {
          counter.addClass("hidden");
        } else {
          counter.removeClass("hidden");
        }
      } else {
        counter.addClass("hidden");
        var results = "Your profile does not have permission to view notifications.";
        target.append(results).removeClass("hidden");
        $("#load-notification-btn").addClass("hidden");
        loader.addClass("hidden");
      }
    });
  },
  filterProducts: function filterProducts() {
    var select_item = $(".selected-wrap");
    $.each(select_item, function (key, row) {
      var row = $(row);
      var selected = row.find(".selected");
      selected.click(function () {
        var clicked = $(this);
        var ul = row.find("ul");
        select_item.find("ul").removeClass("show");
        if (clicked.hasClass("clicked")) {
          ul.removeClass("show");
          clicked.removeClass("clicked");
        } else {
          ul.addClass("show");
          clicked.addClass("clicked");
        }
      });
    });
    /*
    var selects = $("#filter-dropdowns input");
    selects.unbind().on("change", function() {
      var selected = "";
      var cat = $("#cat_sel").val();
      if (cat != "") {
        selected += "category=" + cat + "&";
      }
       selects.each(function(key, row) {
        var row = $(row);
        if (row.val() != "" && row.is(":checked") == true) {
          selected += encodeURIComponent(row.attr("name"));
          selected += "=" + row.val() + "&";
        }
      });
       var port = window.location.port;
      var url = window.location.protocol + "//" + window.location.hostname;
      if (port != "") {
        url += ":" + port;
      }
      url += window.location.pathname;
      window.location.href = url + "?" + selected;
    });
    */
  },

  filtercheckboxes: function filtercheckboxes(selected_this) {

    var isBrand = $(selected_this).attr('name') == 'brand';
    var isFiltered = false;
    if (isBrand === true && selected_this !== false) {
      var selected = $(selected_this).attr('name').replace(/^\D+/g, '').split(']');
      selected = selected[0];
      var value = $(selected_this).val();
      var value = $(selected_this).val();
      var slug = $(selected_this).attr("data-slug");
      var checked = $(selected_this).is(':checked');
      var id_checked = 0;
      var queryString = '';

      if (checked === true) {
        isFiltered = true;

        $('.product-listing-panel').each(function () {
          var parent_wrapper = $(this).parent('.product-grid-item');
          var product_brand = $(this).attr('data-brand-id');
          if (product_brand !== value) {
            parent_wrapper.addClass('hidden');
          }
        });
        queryString += "?brand=" + slug;
        /*
        if(id_checked == 1) {
          queryString += "?brand=" + slug;
        } else {
          queryString += "&brand=" + slug;
        }*/
      }
    } else {
      var filterValues = [];
      var id_checked = 0;
      var queryString = '';
      $('.check-box-item').each(function (key, row) {
        var checked_input = $(row).find('input');
        var checked = $(checked_input).is(':checked');
        var value = $(checked_input).val();
        var name = $(checked_input).attr('name').replace(/^\D+/g, '').split(']');
        var option = $(checked_input).attr('name');
        name = name[0];
        if (checked === true) {
          isFiltered = true;
          var active = active++;
          id_checked++;

          if (option === 'brand') {
            var slug = $(checked_input).attr('data-slug');
            queryString += "?brand=" + slug;
          } else {
            if (id_checked == 1) {
              queryString += "?" + option + "=" + value;
            } else {
              queryString += "&" + option + "=" + value;
            }
          }

          if (filterValues[name]) {
            filterValues[name].push(value);
          } else {
            filterValues[name] = [value];
          }
        }
      });

      $(filterValues).each(function (key, row) {
        if (row !== undefined) {
          $('.product-listing-panel').each(function () {
            var details = $(this).attr('data-options');
            details = JSON.parse(details);
            var parent_wrapper = $(this).parent('.product-grid-item');
            if (parent_wrapper.hasClass('hidden') === false) {
              for (var x = 0; x < details.length; x++) {
                var item = details[x];
                if (item.attribute_id == key) {
                  if ($.inArray(item.number_value, row) === -1) {
                    parent_wrapper.addClass('hidden');
                  }
                }
              }
            }
          });
        }
      });
    }
    //console.log('QUERY STRING: ', queryString);
    if (history.pushState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryString;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    // reset
    if (isFiltered == false) {
      $('.product-grid-item').removeClass('hidden');
      $('.check-box-item').removeClass('disabled');
      $('.check-box-item input').removeAttr("disabled");
    } else {
      $('.check-box-item').each(function (key, row) {
        var checked_input = $(row).find('input');
        var value = $(checked_input).val();
        var isBrand = $(checked_input).attr('name') == 'brand';
        var name = $(checked_input).attr('name').replace(/^\D+/g, '').split(']');
        name = name[0];

        if (selected !== name && isBrand === false) {
          var isValid = false;
          var product_wrapper = $('.product-grid-item:not(.hidden)');

          product_wrapper.each(function () {
            var product = $(this).find('.product-listing-panel');
            var details = $(product).attr('data-options');
            details = JSON.parse(details);
            for (var x = 0; x < details.length; x++) {
              var item = details[x];
              if (item.attribute_id == name) {
                if (item.number_value == value) {
                  isValid = true;
                }
              }
            }
          });

          if (isValid == false) {
            $(row).addClass('disabled');
            checked_input.attr("disabled", true);
            checked_input.prop("checked", false);
          } else {
            $(row).removeClass('disabled');
            checked_input.removeAttr("disabled");
          }
        }

        if (selected !== '' && isBrand === true) {
          var isValid = false;
          var product_wrapper = $('.product-grid-item:not(.hidden)');

          product_wrapper.each(function () {
            var product = $(this).find('.product-listing-panel');
            var productBrand = $(product).attr('data-brand-id');
            if (productBrand === value) {
              isValid = true;
            }
          });

          if (isValid == false) {
            $(row).addClass('disabled');
            checked_input.attr("disabled", true);
            checked_input.prop("checked", false);
          } else {
            $(row).removeClass('disabled');
            checked_input.removeAttr("disabled");
          }
        }
      });
    }
  },

  init: function init() {
    if ($(".select-product-btn").length > 0) {
      $(".select-product-btn").unbind().on("click", function (e) {
        e.preventDefault();
        var data = {
          title: "Choose Your Product",
          subTitle: "",
          content: app.loader,
          template: "categories"
        };
        app.fancyboxDialog(data);
      });
    }

    if ($("#notifications").length > 0) {
      app.notifications();
    }

    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      items: 1
    });

    if ($(".check_wrap_item").length > 0) {
      var chk_boxes = $(".check_wrap_item");
      chk_boxes.each(function (key, row) {
        var selected = $(row);
        var checkbox = selected.find("input");
        if (checkbox.is(":checked") == false) {
          checkbox.prop("checked", false);
          selected.removeClass("selected");
        } else {
          checkbox.prop("checked", true);
          selected.addClass("selected");
        }
      });
    }

    if ($("#homepage-product-btn").length > 0) {
      var homepage_product_btn = $("#homepage-product-btn");
      var homepage_product_btn_clone = $("#homepage-product-btn-clone");
      homepage_product_btn.click(function () {
        $("ul#product-sub-menu-homepage").toggleClass("active");
      });
      homepage_product_btn_clone.click(function () {
        $("ul#product-sub-menu-homepage-clone").toggleClass("active");
      });
    }

    if ($(".menu_trigger").length > 0) {
      var submenu = $(".menu_trigger");
      var menu_btm = $("#hamburger_button");
      submenu.click(function (e) {
        e.preventDefault();
        $(".site-header").toggleClass("active");
        $("body").toggleClass("menu-active");
        menu_btm.toggleClass("is-active");
        e.stopPropagation();
      });
    }

    var dropdown_btn = $("#account-dropdown-tigger, #menu-dropdown-tigger");
    dropdown_btn.click(function () {
      var selected = $(this);
      var parent = selected.parent();
      if (parent.hasClass("active")) {
        parent.removeClass("active");
      } else {
        $("nav.site-menu li").removeClass("active");
        parent.addClass("active");
      }
    });

    $('html').click(function () {
      $(".site-header").removeClass("active");
      $("#sub_menu_items.parents li").removeClass("active");
      $("body").removeClass("menu-active");
      menu_btm.removeClass("is-active");
      dropdown_btn.removeClass("active");
    });

    if ($("#sub_menu_items").length > 0) {
      $("#sub_menu_items.parents li a.parent_link").click(function () {
        var selected = $(this).parent();
        if (selected.hasClass("active")) {
          selected.removeClass("active");
        } else {
          $("#sub_menu_items.parents li").removeClass("active");
          selected.addClass("active");
        }
        return false;
      });
    }

    /*
    if ($("#site-header").length > 0) {
      
       var doc = document.documentElement;
      var w   = window;
         /*
      define four variables: curScroll, prevScroll, curDirection, prevDirection
      
         var curScroll;
      var prevScroll = w.scrollY || doc.scrollTop;
      var curDirection = 0;
      var prevDirection = 0;
         /*
      how it works:
      -------------
      create a scroll event listener
      create function to check scroll position on each scroll event,
      compare curScroll and prevScroll values to find the scroll direction
      scroll up - 1, scroll down - 2, initial - 0
      then set the direction value to curDirection
      compare curDirection and prevDirection
      if it is different, call a function to show or hide the header
      example:
      step 1: user scrolls down: curDirection 2, prevDirection 0 > hide header
      step 2: user scrolls down again: curDirection 2, prevDirection 2 > already hidden, do nothing
      step 3: user scrolls up: curDirection 1, prevDirection 2 > show header
      
         var header = document.getElementById("site-header");
      var toggled;
      var threshold = 500;
         var checkScroll = function() {
        curScroll = w.scrollY || doc.scrollTop;
        
        
        if(curScroll > prevScroll) {
          // scrolled down
          curDirection = 2;
        }
        else {
          //scrolled up
          curDirection = 1;
        }
           if(curDirection !== prevDirection) {
          toggled = toggleHeader();
        }
           if(curScroll < threshold) {
          header.classList.remove('hide');
          header.classList.remove('scrolled');
        }
           prevScroll = curScroll;
        if(toggled) {
          prevDirection = curDirection;
        }
      };
         var toggleHeader = function() { 
        toggled = true;
        if(curDirection === 2 && curScroll > threshold) {
          header.classList.add('hide');
          header.classList.remove('scrolled');
        }	
        else if (curDirection === 1) {
          header.classList.remove('hide');
          header.classList.add('scrolled');
        }
        else {
          toggled = false;
        }
        return toggled;
      };
         window.addEventListener('scroll', checkScroll);
       }
    
    */

    // HOMEPAGE CAROSEUL
    var testimonials = $(".testimonials");
    if (testimonials.length > 0) {
      testimonials.slick({
        dots: false,
        slidesToScroll: 1,
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        prevArrow: "#testimonials-left",
        nextArrow: "#testimonials-right"
      });
    }

    // img-gallery
    var gallery = $(".gallery-outter");
    if (gallery.length > 0) {
      gallery.slick({
        dots: false,
        autoplay: false,
        infinite: false,
        slidesToShow: 4,
        centerMode: true,
        variableWidth: false,
        responsive: [{
          breakpoint: 1300,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '5vw',
            slidesToShow: 3,
            autoplay: true,
            infinite: true
          }
        }, {
          breakpoint: 900,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '25vw',
            slidesToShow: 1,
            autoplay: true,
            infinite: true
          }
        }, {
          breakpoint: 600,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '15vw',
            slidesToShow: 1,
            autoplay: true,
            infinite: true
          }
        }]
      });
    }

    // Featured Products Carousel
    var featuredCarousel = $(".featured_products_outter");
    if (featuredCarousel.length > 0) {
      featuredCarousel.slick({
        dots: false,
        autoplay: false,
        infinite: false,
        slidesToShow: 4,
        centerMode: false,
        variableWidth: false,
        prevArrow: "#featured-left",
        nextArrow: "#featured-right",
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    }

    // Latest NewsOther 
    var LatestNewsCarousel = $(".latest_news_outter");
    if (LatestNewsCarousel.length > 0) {
      LatestNewsCarousel.slick({
        dots: false,
        autoplay: false,
        infinite: false,
        slidesToShow: 3,
        centerMode: false,
        variableWidth: false,
        prevArrow: "#latest-news-left",
        nextArrow: "#latest-news-right",
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    }

    var validate_btn = $('#validate-btn');
    if (validate_btn.length > 0) {
      app.validateForm();
    }

    var products_dropdown_btn = $("#product-dropdown-tigger, #buynow-dropdown-tigger");
    products_dropdown_btn.click(function (e) {
      e.preventDefault();
      var selected = $(this);
      var parent = selected.parent();
      if (parent.hasClass("active")) {
        parent.removeClass("active");
      } else {
        $("nav.site-menu li").removeClass("active");
        parent.addClass("active");
      }
    });

    if ($("#select-wrap").length > 0) {
      var container = $("#select-wrap");
      var input = container.find("input");
      container.find("span").click(function () {
        var selected = $(this);
        container.find("span").removeClass("selected");
        selected.addClass("selected");
        input.val(selected.attr("data-selected-value"));
      });
    }

    if ($("#member-details-wrap").length > 0 || $("#product-details-actions").length > 0) {
      app.dashboard();
    }

    if ($(".get-a-quote-product").length > 0) {
      $(".get-a-quote-product").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Locate Seller",
          subTitle: "",
          content: app.loader,
          template: "get-a-quote-product",
          productId: selected.attr("data-productId")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".call-dealer-btn").length > 0) {
      $(".call-dealer-btn").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Contact " + selected.attr("data-dealer-title"),
          subTitle: "",
          content: app.loader,
          template: "contact",
          orderId: selected.attr("data-orderId"),
          dealer: selected.attr("data-dealer-title"),
          telephone: selected.attr("data-dealer-telephone")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".contact-dealer-btn").length > 0) {
      $(".contact-dealer-btn").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Contact " + selected.attr("data-dealer-title"),
          subTitle: "",
          content: app.loader,
          template: "dealer_contact",
          notification: selected.attr("data-notification-id"),
          dealer: selected.attr("data-dealer-title"),
          email: selected.attr("data-dealer-email"),
          telephone: selected.attr("data-dealer-telephone"),
          address: selected.attr("data-dealer-address")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($('.check-box-item').length > 0) {

      $(document).on("ifChanged", "input", function (event) {
        var selected = this;
        app.filtercheckboxes(selected);
      });
    }

    if ($(".delete-offer-btn").length > 0) {
      $(".delete-offer-btn").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Delete Offer",
          subTitle: "",
          content: app.loader,
          template: "achiveOffer",
          id: selected.attr("data-offerid") !== undefined ? selected.attr("data-offerid") : selected.attr("data-quote-product-id")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".terms-btn").length > 0) {
      $(".terms-btn").click(function (e) {
        e.preventDefault();
        var data = {
          title: "Terms and Conditions:",
          subTitle: "",
          content: app.loader,
          template: "terms"
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".privacy-btn").length > 0) {
      $(".privacy-btn").click(function (e) {
        e.preventDefault();
        var data = {
          title: "Privacy Policy:",
          subTitle: "",
          content: app.loader,
          template: "privacy"
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".view-offers-details-btn").length > 0) {
      $(".view-offers-details-btn").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Your Offer Details",
          subTitle: "",
          content: app.loader,
          template: "view_offer",
          orderId: selected.attr("data-product-offer-id")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($("#confirm_btn").length > 0) {
      $("#confirm_btn").click(function (e) {
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Confirm Sale",
          subTitle: "",
          content: app.loader,
          template: "confirm_sale",
          orderId: selected.attr("data-product-offer-id"),
          dealer: selected.attr("data-dealer"),
          customer: selected.attr("data-customer")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($(".delete_image").length > 0) {
      console.log("hello World");
      $(".delete_image").click(function (e) {
        console.log("clicked");
        e.preventDefault();
        var selected = $(this);
        var data = {
          title: "Delete Image",
          subTitle: "",
          content: app.loader,
          template: "delete_image",
          imageId: selected.attr("data-image-id")
        };
        app.fancyboxDialog(data);
      });
    }

    if ($("#saleyard_payment_frm").length > 0) {
      console.log("hello world ");
      $.ajax({
        url: "/forms/payment",
        success: function success(response, textStatus, xhr) {
          $("#dialog-content").html(response);
          console.log("hello world 1");
          var form = $("#submit_payment_frm");
          form.find('input[name="id"]').val(data.id);
          form.find('input[name="quote_product_id"]').val(data.productId);
          var stripe_env_data = $('input[name="token"]').val();
          // Stripe API Key
          var loader = $("#payment-form-loader");
          var stripe = Stripe(stripe_env_data);
          var elements = stripe.elements();
          // Custom Styling
          var style = {
            base: {
              color: "#32325d",
              lineHeight: "24px",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4"
              }
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a"
            }
          };
          // Create an instance of the card Element
          var card = elements.create("card", { style: style });
          // Add an instance of the card Element into the `card-element` <div>
          card.mount("#card-element");
          // Handle real-time validation errors from the card Element.
          card.addEventListener("change", function (event) {
            var displayError = document.getElementById("card-errors");
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = "";
            }
          });
          // Handle form submission
          var form = document.getElementById("submit_payment_frm");
          form.addEventListener("submit", function (event) {
            event.preventDefault();
            $("#submit_payment_frm").css("opacity", 0.5);
            loader.removeClass("hidden");
            stripe.createToken(card).then(function (result) {
              if (result.error) {
                // Inform the user if there was an error
                var errorElement = document.getElementById("card-errors");
                errorElement.textContent = result.error.message;
                $("#submit_payment_frm").css("opacity", 1);
                loader.addClass("hidden");
              } else {
                stripeTokenHandler(result.token);
              }
            });
          });
          // Send Stripe Token to Server
          function stripeTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById("submit_payment_frm");
            // Add Stripe Token to hidden input
            var hiddenInput = document.createElement("input");
            hiddenInput.setAttribute("type", "hidden");
            hiddenInput.setAttribute("name", "stripeToken");
            hiddenInput.setAttribute("value", token.id);
            form.appendChild(hiddenInput);
            // Submit form
            form.submit();
          }
        },
        error: function error(XMLHttpRequest, textStatus, errorThrown) {
          var response = '<div style="padding:20px;text-align:center;">Our Payment system isn\'t available right now. Please try again later.</div>';
          $("#dialog-content").html(response);
        }
      });
    }

    if ($(".youtubeinput").length > 0) {

      var loadedurl = $(".youtubeinput").val();
      if (loadedurl != undefined || url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = loadedurl.match(regExp);
        if (match && match[2].length == 11) {
          // Do anything for being valid
          // if need to change the url to embed url then use below line
          console.log("valid");
          $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
          $('#ytplayerSide').delay(1000).queue(function () {
            $('#ytplayerSide').removeClass("hidden");
          });
        } else {
          $('#ytplayerSide').addClass("hidden");
        }
      }

      $('.youtubeinput').on('keyup', function (e) {
        var count = $(this).val().length;
        var url = $(this).val();
        if (url != undefined || url != '') {
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
          var match = url.match(regExp);
          if (match && match[2].length == 11) {
            // Do anything for being valid
            // if need to change the url to embed url then use below line
            console.log("valid");
            $('#ytplayerSide').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0');
            $('#ytplayerSide').delay(1000).queue(function () {
              $('#ytplayerSide').removeClass("hidden");
            });
          } else {
            $('#ytplayerSide').addClass("hidden");
          }
        }
      });
    }

    if ($('#add-to-cart-btn').length > 0) {
      $("#add-to-cart-btn").click(function (e) {
        e.preventDefault();
        var form = $('#add-to-cart-frm');
        var input = form.serialize();
        $.ajax({
          url: form.attr("action"),
          type: "POST",
          data: input
        }).done(function (response) {
          var checkout_btn = $('#checkout-btn');
          var current = checkout_btn.attr('data-total');
          checkout_btn.attr('data-total', parseInt(current) + 1);
          var data = {
            title: response.product_name + " added to Cart",
            subTitle: "",
            content: app.loader,
            template: "added-to-cart"
          };
          app.fancyboxDialog(data);
        });
      });
    }

    if ($('#invites_frm').length > 0) {
      $('#invites_frm_btn').click(function (e) {
        e.preventDefault();
        var invites_frm = $('#invites_frm');
        var data = { email: $('#invites_frm_email').val(), message: $('#email-body').html() };
        $.ajax({
          url: invites_frm.attr('action'),
          type: invites_frm.attr('method'),
          data: data,
          success: function success(data, textStatus, jqXHR) {
            //data - response from server
            console.log('SUCCESS DATA = ', data);
          },
          error: function error(jqXHR, textStatus, errorThrown) {
            console.log('SUCCESS ERROR = ', textStatus);
          }
        });
      });
    }

    if ($("#sub_menu_sel").length == 1) {
      var http = $(location).attr("protocol");
      var domain = $(location).attr("host");
      $("#sub_menu_sel").unbind().change(function (key, row) {
        var selected = $(this).val();
        var url = http + "//" + domain + "/" + selected;
        window.location = url;
      });
    }

    if ($("#show-product-filter-btn").length > 0) {
      var filterDropdowns = $(".product-sidebar");
      var showProductFilterBtn = $("#show-product-filter-btn");
      showProductFilterBtn.click(function () {
        if (filterDropdowns.hasClass("hide-from-mobile") == true) {
          filterDropdowns.removeClass("hide-from-mobile");
          showProductFilterBtn.addClass("active");
        } else {
          filterDropdowns.addClass("hide-from-mobile");
          showProductFilterBtn.removeClass("active");
        }
      });
    }

    if ($("#filter-dropdowns").length > 0) {
      app.filterProducts();
    }

    if ($("#search-btn").length > 0) {
      $("#search-btn").click(function (e) {
        e.preventDefault();
        $.ajax({
          url: "/form/search"
        }).done(function (response) {
          // Open customized confirmation dialog window
          $.fancybox.open({
            type: "html",
            src: response,
            opts: {
              clickSlide: false,
              clickOutside: false,
              afterShow: function afterShow(instance, current) {
                var form = $("#search-frm-dialog form");
                var btn = $("#trigger-search-submit");
                btn.click(function () {
                  form.submit();
                });
              }
            }
          });
        });
      });
    }

    if ($("#trigger-search-submit").length > 0) {

      $("#trigger-search-submit").click(function (e) {
        console.log("button clicked");
        $(".search-frm-home form").submit();
        $(".search-frm-home form").addClass("active");
      });

      $(".search-frm-home form").submit(function (e) {
        loader.addClass("show");
      });
    }

    if ($(".search_mobile_btn").length > 0) {
      $(".search_mobile_btn").click(function (e) {
        $(".search_mobile").addClass("active");
      });
      $(".close_search").click(function (e) {
        $(".search_mobile").removeClass("active");
      });
    }

    // ACCORDIAN
    if ($("#accordian").length > 0) {
      var accordian = $("#accordian");
      var items = accordian.find("h3.accordion");
      items.each(function (key, row) {
        var row = $(row);
        row.click(function (e) {
          var selected = $(this);
          var active = selected.hasClass("active");
          var panel = selected.next(".accordion-panel");
          var scrollHeight = panel.prop("scrollHeight");
          // ANIMATE
          accordian.find(".accordion").removeClass("active");
          accordian.find(".accordion-panel").css("maxHeight", 0);
          if (active == false) {
            selected.addClass("active");
            panel.css("maxHeight", scrollHeight + "px");
          }
        });
      });
    }

    // DISABLE QUOTE BUTTONS
    if ($(".disable-btn-onclick").length > 0) {
      var disable_btn = $(".disable-btn-onclick");
      disable_btn.click(function (e) {
        var parentForm = $(this).closest("form");
        parentForm = parentForm[0];
        var required = $(parentForm).find('input[required="required"]');
        var isValid = true;

        $.each(required, function (key, row) {
          var row = $(row);
          if (row.val() == "") {
            isValid = false;
          }
        });

        if (isValid == true) {
          e.preventDefault();
          disable_btn.prop("disabled", true);
          parentForm.submit();
          var loader = $("#loader");
          if (loader.length > 0) {
            loader.removeClass("hidden");
          }
        }
      });
    }

    if ($('#delivery_sel').length > 0) {
      $('#delivery_sel').on('change', function () {
        $('#delivery_btn').trigger('click');
      });
      // qty
      $('.cart-quantity').on('change', function () {
        var selected = $(this);
        var target = selected.data('qty');
        var current = selected.val();
        var button = selected.next('button');
        if (target != current) {
          button.removeClass('hidden');
        } else {
          button.addClass('hidden');
        }
      });
    }

    if ($('.variant_change_sel').length > 0) {
      var variant_change_sel = $('.variant_change_sel');
      var option_unavailable = $('#option-unavailable');
      var add_to_cart_btn = $('#add-to-cart-btn');
      variant_change_sel.on('change', function (key, row) {
        var selected = "";
        $.each(variant_change_sel, function (key, row) {
          var row = $(row).val();
          selected += row + " | ";
        });
        selected = selected.substring(0, selected.length - 3);
        $('.sku-item').addClass("hidden");
        var sku_option = $("#sku_hash option:contains(" + selected + ")");
        if (sku_option.length == 0) {
          option_unavailable.removeClass('hidden');
          add_to_cart_btn.addClass('hidden');
        } else {
          sku_option.attr('selected', 'selected');
          add_to_cart_btn.removeClass('hidden');
          option_unavailable.addClass('hidden');
          $('.sku-item[data-sku-value="' + $("#sku_hash").val() + '"]').removeClass("hidden");
        }
      });
    }

    // homepage dropdown
    if ($('#parent_sel').length > 0) {
      var category_sel = $('#category_sel');
      var loadingSearch = $('.loading-search');
      var loader = $(".new_pageload");

      if ($('#loadproduct').length > 0) {
        var productname = $('#loadproduct').val();
        var productCategory = $('#loadcategory').val();
        var selected = $("#parent_sel").find("option[data-slug='" + productname + "']").val();

        $("#parent_sel").find("option[data-slug='" + productname + "']").attr('selected', 'selected');

        category_sel.prop('disabled', true);
        loadingSearch.addClass('show');

        $.ajax({
          url: '/api/categories/' + selected
        }).done(function (response) {
          category_sel.empty();

          var html = '<option value="all"> View All</option>';

          $.each(response, function (key, row) {
            if (row.slug == productCategory) {
              html += '<option value="' + row.slug + '" selected> ' + row.name + '</option>';
            } else {
              html += '<option value="' + row.slug + '"> ' + row.name + '</option>';
            }
          });
          category_sel.html(html);
          category_sel.removeClass('hidden');
          loadingSearch.removeClass('show');
          category_sel.prop('disabled', false);
        });
      }

      $('#parent_sel').on('change', function () {

        var selected = $(this).val();
        category_sel.prop('disabled', true);
        loadingSearch.addClass('show');

        $.ajax({
          url: '/api/categories/' + selected
        }).done(function (response) {
          category_sel.empty();

          var html = '<option value="all"> View All</option>';

          $.each(response, function (key, row) {
            html += '<option value="' + row.slug + '"> ' + row.name + '</option>';
          });
          category_sel.html(html);
          category_sel.removeClass('hidden');
          loadingSearch.removeClass('show');
          category_sel.prop('disabled', false);
        });
      });

      $('#get_a_quote_btn').click(function (e) {
        e.preventDefault();
        var selected = $('#category_sel').val();
        var parent_slug = $("#parent_sel option:selected").attr('data-slug');
        var queryString = '';

        if (selected == "default") {
          selected = 0;
        }

        if (parent_slug == '0' || selected == '0') {
          //console.log("nosearch");
          return false;
        } else {
          //console.log("searched");
          loader.addClass("show");
          queryString = "?product=" + parent_slug + "&category=" + selected;
          window.location = "/category/" + parent_slug + "/" + selected;
        }

        if (history.pushState) {
          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryString;
          window.history.pushState({ path: newurl }, '', newurl);
        }
      });
    }
    //

    if ($('#classified_currency_sel').length > 0) {

      $('#classified_currency_sel').on('change', function () {
        var priceset = '';
        if ($(this).val() === 'gbp') {
          if ($('#cureur').val() === '') {
            priceset = '';
          } else {
            priceset = $('#cureur').val();
          }

          $('#cureur').addClass('hidden');
          $('#curgbp').removeClass('hidden');
          $('.pricingoption').find('#cureur').val('');
          $('.pricingoption').find('#curgbp').val(priceset);
        } else {

          if ($('#curgbp').val() === '') {
            priceset = '';
          } else {
            priceset = $('#curgbp').val();
          }

          $('#curgbp').addClass('hidden');
          $('#cureur').removeClass('hidden');
          $('.pricingoption').find('#curgbp').val('');
          $('.pricingoption').find('#cureur').val(priceset);
        }
      });
    }

    // marketplace category dropdown
    if ($('#classified_parent_sel').length > 0) {
      var category_sel = $('#classified_category_sel');
      var loadingSearch = $('.loading-search');
      var loader = $(".new_pageload");

      if ($('#loadproduct').length > 0) {
        var productname = $('#loadproduct').val();
        var productCategory = $('#loadcategory').val();
        var selected = $("#classified_parent_sel").find("option[data-slug='" + productname + "']").val();

        $("#classified_parent_sel").find("option[data-slug='" + productname + "']").attr('selected', 'selected');

        category_sel.prop('disabled', true);
        loadingSearch.addClass('show');

        $.ajax({
          url: '/api/categories/' + selected
        }).done(function (response) {
          category_sel.empty();

          var html = '<option value="all"> View All</option>';

          $.each(response, function (key, row) {
            if (row.slug == productCategory) {
              html += '<option value="' + row.slug + '" selected> ' + row.name + '</option>';
            } else {
              html += '<option value="' + row.slug + '"> ' + row.name + '</option>';
            }
          });
          category_sel.html(html);
          category_sel.removeClass('hidden');
          loadingSearch.removeClass('show');
          category_sel.prop('disabled', false);
        });
      }

      $('#classified_category_sel').on('change', function () {
        var selected = $(this).val();
        if (selected === 'suggest_sub') {
          $('.suggested_sub_category').removeClass('hidden');
        } else {
          $('.suggested_sub_category').addClass('hidden');
        }
      });

      $('#brand_sel').on('change', function () {
        var selected = $(this).val();
        if (selected === 'suggest_brand') {
          $('.suggested_brand').removeClass('hidden');
        } else {
          $('.suggested_brand').addClass('hidden');
        }
      });

      $('#classified_parent_sel').on('change', function () {

        var selected = $(this).val();
        console.log(selected);
        if (selected === 'suggest') {
          $('.subselectshow').addClass('hidden');
          $('.suggested_sub_category').addClass('hidden');
          $('.suggested_category').removeClass('hidden');
        } else {
          $('.suggested_category').addClass('hidden');
          $('.subselectshow').removeClass('hidden');
          $('.suggested_sub_category').addClass('hidden');
        }

        category_sel.prop('disabled', true);
        loadingSearch.addClass('show');

        $.ajax({
          url: '/api/categories/' + selected
        }).done(function (response) {
          category_sel.empty();

          var html = '';
          var html = '<option value=""> Select Sub Category</option>';

          $.each(response, function (key, row) {
            html += '<option value="' + row.id + '"> ' + row.name + '</option>';
          });

          html += '<optgroup label="_________"><option value="suggest_sub" data-slug="0">Sugest New Sub Category</option></optgroup>';
          category_sel.html(html);
          category_sel.removeClass('hidden');
          loadingSearch.removeClass('show');
          category_sel.prop('disabled', false);
        });
      });
    }
    //

    if ($('.imguploadcontainer').length > 0) {
      console.log("hello world");

      if (window.File && window.FileList && window.FileReader) {
        $("#files").on("change", function (e) {
          var files = e.target.files,
              filesLength = files.length;
          for (var i = 0; i < filesLength; i++) {
            var f = files[i];
            var fileReader = new FileReader();
            fileReader.onload = function (e) {
              var file = e.target;
              $("<div class=\"pip\">" + "<img class=\"imageThumb\" src=\"" + e.target.result + "\" title=\"" + file.name + "\"/>" + "<button class=\"remove\">" + "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" >" + "<path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6 18L18 6M6 6l12 12\" />" + "</svg>" + "</button>" + "</div>").insertBefore("#uploadimglabel");

              $(".remove").click(function () {
                $(this).parent(".pip").remove();
              });

              // Old code here
              /*$("<img></img>", {
                class: "imageThumb",
                src: e.target.result,
                title: file.name + " | Click to remove"
              }).insertAfter("#files").click(function(){$(this).remove();});*/
            };
            fileReader.readAsDataURL(f);
          }
        });
      } else {
        alert("Your browser doesn't support to File API");
      }
    }

    if ($(".grid_toggle").length > 0) {

      $(".grid_toggle").on("click", function (e) {
        var grid = $(".product-grid");

        grid.addClass("temp");
        if (grid.hasClass('product-grid-third')) {
          grid.addClass("product-grid-full");
          grid.removeClass("product-grid-third");
        } else {
          grid.removeClass("product-grid-full");
          grid.addClass("product-grid-third");
        }

        window.setTimeout(function () {
          grid.removeClass("temp");
        }, 500); //<-- Delay in milliseconds


        $(".switch").toggleClass("active");
      });
    }

    if ($(".select-product-item").length > 0) {
      var btn = $("#counter_submit_btn");
      var all_btn = $("#counter_submit_all_btn");
      var btn_wrap = $(".quote_btn_area");
      var loader = $(".new_pageload");

      all_btn.unbind().on("click", function (e) {
        e.preventDefault();
        var chk_boxes = $('input[name="selected[]"]');
        chk_boxes.prop("checked", true);
        $(".quote-frm-wrap form").submit();
        loader.addClass("show");
      });

      btn.unbind().on("click", function (e) {
        loader.addClass("show");
      });

      $(".select-product-item").unbind().on("click", function (e) {
        var chk = $(this).find("input");
        var product = chk.attr("value");
        var selected = $("[data-product-id=" + product + "]");
        if (chk.is(":checked") == true) {
          chk.prop("checked", false);
          selected.removeClass("selected");
        } else {
          chk.prop("checked", true);
          selected.addClass("selected");
        }
        var counter = $(".product-listing-panel.selected").length;
        var btn_value = btn.val().split(" ");
        btn_value[0] = counter;
        btn.val(btn_value.join(" "));
        if (counter == 0) {
          btn.addClass("hidden");
          all_btn.removeClass("hidden");
          btn_wrap.removeClass("show");
        } else {
          btn.removeClass("hidden");
          all_btn.addClass("hidden");
          btn_wrap.addClass("show");
        }
      });
      // attach notes
      if ($("#attach-notes").length > 0) {
        $("#attach-notes").click(function () {
          var data = {
            title: "Add Notes:",
            subTitle: "",
            content: app.loader,
            template: "notes"
          };
          app.fancyboxDialog(data);
        });
      }
    }
  }
};

$(document).ready(function () {
  app.init();

  if ($('.check-box-item').length > 0) {
    var selected = false;
    app.filtercheckboxes(selected);
  }
});